import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  const [strainRecommendation, setStrainRecommendation] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchRecommendation = async () => {
      setIsLoading(true);
      try {
        // Simulating an API call. Replace this with an actual API call in the future
        await new Promise(resolve => setTimeout(resolve, 1000));
        setStrainRecommendation('OG Kush');
      } catch (error) {
        console.error('Error fetching recommendation:', error);
        setStrainRecommendation('Unable to fetch recommendation');
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecommendation();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Kannabis Advanced Ventures</h1>
        <p>
          Your friendly cannabis strain recommendation service!
        </p>
        {isLoading ? (
          <p>Loading recommendation...</p>
        ) : (
          <p>Today's recommended strain: {strainRecommendation}</p>
        )}
        <a
          className="App-link"
          href="https://www.kannabisadvancedventures.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More About Our Services
        </a>
      </header>
    </div>
  );
}

export default App;